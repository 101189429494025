import React from 'react'
import { LayoutA } from '../layouts'
import {Link} from 'gatsby'

class Pricing extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="PricingPage">
          <div className="s10 grid-restrain ">
            <div className="grid-row h-align feature-table full-8">
              <div className="grid-item grid-row card sr4 full-s5">
                <div className="grid-item feature-plan grid-row grid-column premium">
                  <div className="title">Personal Cloud</div>
                  <div className="features">
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                    <div className="new construct">+ Feature</div>
                    <div className="new construct">+ Feature</div>
                    <div className="new construct">+ Feature</div>
                  </div>
                  <div className="grid-item"></div>
                  <div className="price">
                    <div>$14.99/month</div>
                    <Link className="btn-primary" to="/premium" >
                      + Download
                    </Link>
                  </div>
                </div>
              </div>
              <div className="grid-item grid-row card sr4 full-s5">
                <div className="grid-item feature-plan grid-row grid-column team">
                  <div className="title">Teams</div>
                  <div className="features">
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                    <div className="new">+ Feature</div>
                  </div>
                  <div className="grid-item"></div>
                  <div className="price">
                    <div>$59/month</div>
                    <Link className="btn-primary" to="/teams">
                      + Create Team
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutA>
    )
  }

}

export default Pricing;
